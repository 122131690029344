.form{
    display: flex;
    flex-direction: column;
    max-width: 752px;
    width: 100%;
}

.input-case{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 0 35px;

    &>input , textarea{
        max-width: 742px;
        width: calc(100% - 10px);
        height: 22px;
        color: #000000;
        border:transparent;
        border-bottom: 2px solid #c2d7e9;
        padding: 26px 4px 10px;
        font-size: 16px;
        line-height: 1.33;
        outline: none;
    }
}

.plase-holder{
    position: absolute;
    top: 9px;
    left: 4px;
    color: #000000;
    opacity: .5;
    transition: .5s all ease;
    pointer-events: none;
}

.text-input{
    &>textarea{
        height: 64px !important;
        resize: none;
    }
}

.plase-holder__act{
    top: 0px;
    transform: scale(0.7);
}

.error{
    position: absolute;
    font-size: 14px;
    color: #f95d51;
    bottom: -19px;
}

.error-import{
    border: 1px solid #f95d51;
}

.input-check{
    display: flex;
    flex-direction: column;
    position: relative;
}

.error-all{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f95d51;
    margin: 30px 0 0;
    &>span{
        font-size: 18px;
        color:#ffffff ;
        opacity: 1;
        margin: 20px 0 20px;
    }
}

.send-display{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0b61a6;
    margin: 30px 0 0;
    &>span{
        font-size: 18px;
        color:#ffffff ;
        opacity: 1;
        margin: 20px 0 20px;
    }
}