.who-it-work{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 85px;
    opacity: 1;
    transition: 0.2s all linear;
    &>div>h1{
        margin-bottom: 105px;
    }
}


.icon-info{
    display: flex;
    margin-bottom: 45px;
    &_case{
        display: flex;
        flex-direction: column;
        padding-left: 25px;
        padding-bottom: 45px;
        &>span{
            display: block;
        }
    }

    &_title{
        font-size: 20px;
        font-weight: 600;
        color: #2e61a1;
        opacity: 1;
    }

    &_info{
        max-width: 571px;
        font-size: 18px;
        font-weight: 400;
        opacity: 1;
        color: #333333;
    }

    &>div{
        position: relative;

        img{
            border-radius: 100%;
            border: 2px solid #2e61a1;
        }
    }
}

.stick{
    position: absolute;
    height: 100%;
    width: 2px;
    left: 50%;
    bottom: -50%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    background: #dfe7ec;
    z-index: -1;
}

