.magnetto-solutions{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fafcfd;
    padding: 90px 0 105px;

    &_case{
        &>h1{
            font-size: 38px;
            text-align: center;
            color: #073f6c;
            padding: 0 0 80px;
        }
    }
}

.case-img-info{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
}

.case-big-info{
    position: relative;
    width: 300px;
    padding: 30px 30px 40px;
    background-color: #ffffff;
    border: 0px solid transparent;
    border-radius: 10px;
    box-shadow: #0000001a 0px 0px 10px 0px;
    margin-bottom: 32px;
    transition: 0.2s all linear;
    z-index: 0;

    &_title{
        font-size:20px;
        font-weight: 600;
        margin:32px 0 15px;
    }

    &_info{
        font-size: 14px;
    }

    &>span{
        display: block;
        opacity: 1;
    }
    &:hover{
        box-shadow: none;
    }

    &__hover{
        top:100px;
        z-index: -1;
    }

    &__act_anim{
        animation: animUp 0.5s ;
    }
}

@keyframes animUp{
    0%{
        transform: translateY(100%);
        opacity: 0;
    }

    100%{
        transform: translateY(0%);
        opacity: 1;
    }
}

@media(max-width:1100px){
    .case-img-info{
        justify-content: space-around;
    }
}


