body{
    margin: 0;
    padding: 0;
}

button,span,h1,h2,h3,h4,h5{
    font-family: 'Open Sans', sans-serif;
    white-space: break-spaces;
}

h1{
    font-size: 42px;
    text-align: center;
}

h5{
    font-size: 14px;
    font-weight: 600;
    color: #2e61a1;
    margin-top: 0;
}

h4{
    font-size: 34px;
    font-weight: 700;
    color: #1e294c;
    margin-top: 0;
}

span{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    opacity: 0.7;
}

.case{
    width: 100%;
    max-width: 1200px;
}

.title-border{
    max-width: 704px;
    border: 4px solid #f2f5f7;
    border-right: 0px;
    border-left: 0px;
    padding: 20px 0;
    margin: 75px 0 90px;
    &>span{
        font-size: 24px;
        font-weight: 600;
        color: #2e61a1;
        opacity: 1; 
    }
}


.blue-button{
    width: 339px;
    height: 50px;
    background-color: #0b61a6;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border: 2px transparent;
    border-radius: 25px;
    margin: 32px 0 0;
    cursor: pointer;
    transition: 0.5s ease;
    &:hover{
        transform: scale(1.02);
    }
}

.sub-button{
    position: relative;
    width: 268px;
    overflow: hidden;
    border-radius: 30px;
    box-shadow: 0px 0px 18px 0px rgb(0 0 0 / 30%);
    margin: 30px auto 10px;
    &_effect{
        position: absolute;
        background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, .1)), to(rgba(255, 255, 255, .4)));
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
        background: -o-linear-gradient(left, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
        background: linear-gradient(90deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
        width: 45px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -60px;
        -webkit-transform: skewX(-45deg);
        -ms-transform: skewX(-45deg);
        transform: skewX(-45deg);
        animation:animEffect 3s linear  infinite ;
    }

    &_submit{
        width: 268px;
        height: 58px;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        background-color: #0b61a6;
        border: 1px transparent;
        border-radius: 30px;
        -moz-border-radius: 30px;
        -webkit-border-radius: 30px;
        cursor: pointer;
    }
}

.blur{
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transition: 0.2s all linear;
    &__act{
        opacity: 1;
    }
}

.none{
    display: none;
}

@keyframes animEffect{
    0%{
        left: -60px;
    }

    20%{
        left:290px;
    }
    100%{
        left:290px
    }
}