.faq{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0 90px;
    overflow: hidden;
}

.slade-case{
    max-width: 760px;
    
}

.slider{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    

    &>div{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_quest{
        font-size: 20px;
        line-height: 1.35;
        font-weight: 600;
        opacity: 1;
        color: #333;
        padding: 27px 0;
        cursor:pointer
    }

    &_answ{
        max-height: 0;
        overflow: hidden;
        font-size: 14px;
        line-height: 1.55;
        font-weight: 400;
        opacity: 1;
        color: #000;
        transition: 0.5s all;
    }

    &_answ__act{
        max-height: max-content; 
        padding: 0 0 40px;
    }

    &__act_anim{
        animation: sideAnim 0.7s ease;
    }
}

@keyframes sideAnim{
    0%{
        transform: translateX(-200%);
        opacity: 0;
    }

    100%{
        transform: translateX(0%);
        opacity:1;
    }
}

.cross-img{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    transition: 0.2s all;
    cursor: pointer;
    &>img{
        padding: 8px;
        opacity: .5;
    }

    &__act{
        background-color: #eeeeee;
    }

    &__act-cross{
        transform: rotate(45deg);
    }
}

#cross-focus{
    background-color: #eeeeee;
}