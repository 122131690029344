.sector{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#fafcfd;
    padding:90px 0 15px;

    &>h1{
        font-size: 38px;
        font-weight: 600;
        color: #1e294c;
        margin-top: 0px;
        margin-bottom: 90px;
    }

    &_case{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        max-width: 1200px;
    }

    &_case-img{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1200px;
        margin-bottom: 80px;


        &>img{
            width: 64px;
            margin: 0 148px 25px;
        }

        &>span{
            font-size: 24px;
            color: #333333;
            opacity: 1;
        }
    }
}

@media(max-width:1109px){
    .sector_case{
        justify-content: space-around;
    }
}