
.form-calc-case{
    position: relative;
    display: flex;
    justify-content: center;
    top: 100px;
    margin: 0px 0 140px;
    padding: 100px 0 0;
}

.calc-title{
    font-size: 42px;
    width: 110%;
    line-height: 1.23;
    font-weight: 600;
    color: #1e294c;
    opacity: 1;
    margin-bottom: 40px;
}

.calc-span{
    font-size: 18px;
    opacity: 1;
}

.input-calc-check{
    &>label{
        display: block;
        margin: 10px 0;
        &>span{
            font-size: 13px;
        }
    }
}

.input-calc{
    &>input{
        max-width: 742px;
        width: calc(100% - 10px);
        height: 22px;
        color: #000000;
        border: transparent;
        border-bottom: 2px solid #c2d7e9;
        padding: 26px 4px 20px;
        margin-bottom: 35px;
        font-size: 16px;
        line-height: 1.33;
        outline: none;
    }
}

.input-num[type=number]::-webkit-inner-spin-button,
.input-num[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none;
}

.input-num-button{
    border: none;
    background: transparent;
    font-size: 26px;
    font-weight:100;
    margin: 0 8px;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
}

.input-num{
    width: 70px;
    height: 60px;
    color: #000000;
    border: transparent;
    border-bottom: 2px solid #c2d7e9;
    padding: 0 4px 0;
    margin: 0 0 35px;
    font-size: 16px;
    line-height: 1.33;
    outline: none;
    text-align: center;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}