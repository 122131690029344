.contact-us{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 105px 0 0;
    &_case{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1160px;
        padding: 60px 0 90px;

        &>h1{
            line-height: 1.23;
            font-weight: 600;
            text-align: center;
        }

        &>span{
            display: block;
            max-width: 560px;
            margin: 0 0 20px;
            opacity: 1;
        }
    }
}