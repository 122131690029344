.footer{
    display: flex;
    justify-content: center;
    padding: 45px 0;
    background-color: #f2f5f7;
    &_case{
        display: flex;
        flex-direction: column;
        align-items: center;

        &>a{
            font-family: 'Open Sans',Arial,sans-serif;
            font-size: 14px;
            color: rgb(46, 97, 161);
            font-weight: 600;
            text-align: center;
            text-decoration:none;
        }

        &>a:first-child{
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
}

.footer-nav{
   
    &>a>button{
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #0b61a6;
        border: 0px transparent;
        background-color: transparent;
        cursor: pointer;
        padding: 0 15px;
        
    }
    
}

.footer-logo{
    width: 150px;
    margin:30px 0 40px;
}

.footer-con{
    &>span{
        font-size: 14px;
        &>a{
            text-decoration:none;
        }
    }
}