.business-benefits{
    display: flex;
    flex-direction: column;
    align-items: center;

    &_case{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1160px;
        width: 100%;
    }

    &_content{
        display: flex;
        width: 100%;
        
        padding: 45px 0 15px;
        &>img{
            width: 560px;
            height: 298px;
            object-fit: cover;
            margin: 0 20px 0 0;
        }
    }
}

.business-benefits-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    margin: 0 0 0 20px;
    

    &_title{
        font-size: 36px;
        font-weight: 700;
        opacity: 1;
        color:#1e294c;
    }

    &_case{
        margin: 0 0 24px;
        &>span{
            font-size: 16px;
            line-height: 1.23;
            font-weight: 600;
            opacity: 1;
        }
        &:first-child{
            margin-bottom: 30px;
        }
    }
    &>span:last-child{
        font-size: 16px;
        line-height: 1.23;
        color: #000000;
        font-weight: 400;
        opacity: 1;
    }
}

@media(max-width:920px){
    .business-benefits_content{
        max-width: 640px;
        flex-direction: column;
        &>img{
            width: 100%;
        }
    }

    .business-benefits-info{
        // margin: 0;
    }

    .title-border{
        max-width: 640px !important;
    }
}

@media(max-width:700px){
    .business-benefits_content{
        &>img{
            margin: 0;
        }
    }
    .business-benefits-info{

    }    
}

