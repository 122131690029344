.policy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    &_case{
        max-width: 760px;
        padding: 0 10px;

        &>span{
            font-size: 18px;
            display: block;
            opacity: 1;
        }
    }
}

.policy-title{
    font-size: 52px;
    line-height: 1.23;
    font-weight: 600;
    text-align: start;
}

.mb24{
    margin: 0 0 24px;
}

.mb100{
    margin: 0 0 100px;
}

.mb150{
    margin: 0 0 150px;
}

.mt150{
    margin:150px 0 24px ;
}

#none{
    display: none;
}