.partner-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0 0;  
    &_title{
        font-size: 42px;
        line-height: 1.23;
        font-weight: 600;
        text-align: center;
        color: #1e294c;
        margin: 0 0 90px;
    }
}

.partner-info-case{
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 0 60px;
}


.partner-info-case_img{
    width: 160px;
    padding-left: 200px;
    margin:0 20px;
    object-fit: contain;
}

.partner-info-case_inform{
    width: 540px;
    margin:0 20px;
    &>h1{
        font-size: 28px;
        line-height: 1.17;
        font-weight: 600;
        color: #1e294c;
        margin: 0 0 23px;
        text-align: start;
    }

    &>span{
        opacity: 1;
    }

    &>a{
        display: block;
        font-size: 16px;
        line-height: 1.55;
        color: #0b61a6;
        text-decoration: none;
        font-family: Arial, Helvetica, sans-serif;
    }
}


@media(max-width:1080px){
    .partner-info_title{
        margin: 0 0 45px;
    }
    .partner-info-case{
        flex-direction: column;
        align-items: center;

        &_img{
            max-width: 600px;
            width: 100%;
            padding: 0;
            margin:0 0 30px;

        }

        &_inform{
            max-width: 600px;
            width: 100%;
        }
    }
}