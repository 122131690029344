.business-icon{
    display: flex;
    flex-direction: column;
    margin: 35px 0 0;
    &_case{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 1160px;
    }
}

.b-case{
    display: flex;
    flex-direction: column;
    max-width: 260px;
    margin: 70px 0 0;
    &>img{
        width: 65px;
    }

}

.b-info{

    &_title{
        font-size: 18px;
        color: #1e294c;
        line-height: 1.35;
        font-weight: 600;
        opacity: 1;
        margin: 23px 0 0; 
    }

    &_text{
        font-size: 14px;
        line-height: 1.55;
        font-weight: 400;
        color: #000;
        opacity: 1;
        margin: 20px 0 0; 
    }

    &>span{
        display: block;
    }
}

@media (max-width:1200px){
    .business-icon{
        &_case{
            justify-content: space-around;
        }
    }
}