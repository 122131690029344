.for-retail{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    padding: 105px 0 0;
    &_img-case>img{
        width: 920px;
    }

    &_text{
        margin: 0 ;
        text-align: start;
        &>h1{
            font-size: 38px;
            font-weight: 600;
            color: #1e294c;
            margin-bottom: 0;
        }
    }

    &_info{

        max-width: 646px;
        padding: 20px 0 0;
        &>span{
            display: block;
            font-size: 18px;
            opacity: 1;
            white-space: break-spaces;
            &:first-child{
                margin: 0 0 40px;
            }
            &:last-child{
                max-width: 530px;
            }
        }
    }
}

@media (max-width:1620px){
    .for-retail{
        position: relative;
        
        &_text{
            padding:0 40px;
        }

        &_img-case{
            overflow: hidden;
            img{
                position: relative;
            }
            
        }
    }
}
@media (max-width:1100px){
    .for-retail{
        
        flex-direction: column;
        &_img-case{
            display: flex;
            justify-content: center;
        }
    }
}

@media (max-width:1100px){
    .for-retail_img-case{
        width: 100%;
        &>img{

        }
    }
}

@media (max-width:800px){
    .for-retail_img-case{
        &>img{
            left: calc(100vw/12);
        }
    }
}

@media (max-width:650px){
    .for-retail_img-case{
        &>img{
            left: calc(100vw/5.5);
        }
    }
}

@media (max-width:560px){
    .for-retail_img-case{
        &>img{
            left: calc(100vw/3);
        }
    }
}