.leng-button{
    text-transform: uppercase;
    font-size: 13px;
    font-family: Arial;
    text-decoration: none;
    margin-left: 11px;
    color: inherit!important;
    text-align: right;
    line-height: 98px;
    font-weight: 600;
    background-color: transparent;
    border: transparent;
    opacity: 0.5;

    &_next{
        cursor: pointer;
        opacity: 1;
        transition: 0.2s all linear;
        &:hover{
            transform: scale(1.1);
        }
    }
}
