.intro{
    display: flex;
    justify-content: center;
    background-color: #fafcfd;
    padding: 180px 0 160px 0;
}

.intro-case{
    display: flex;
    justify-content: space-between;
    &>div{
        margin: 0 20px;
    }

    &>img{
        width: 528px;
        height: 359px;
        margin: 0 66px;
    }
}

.intro-info{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 460px;
}

@media(max-width:1250px){
    .intro-case{
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

@media(max-width:1177px){
    .intro-case{
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .intro-info{
        align-items: center;
        &>h5,h4,span{
            text-align: center;
        }
    }
}

@media(max-width:600px){
    .intro-case>img{
        width: 100%;
        max-width: 528px;
        height: auto;
        margin: 0 66px;
    }
}