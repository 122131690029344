.header{
    position: relative;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
    background-color: transparent;
    z-index: 1;
    transition: 0.5s all ease;
    &_case{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
    }

    &__act{
        background-color: #fff;
        box-shadow: rgb(0 0 0 / 13%) 0 4px 16px;
    }
}



.nav-header{
    display: flex;
    align-items: center;
    &>div{
        &>a>button{
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            background-color: transparent;
            border:2px transparent;
            margin-left: 20px;
            cursor: pointer;
        }

        margin: 0 20px 0px 0;
    }
    &>div:last-child{
        margin: 0 0px 0px 20px;
    }
}

.logo-header{
    &>a>img{
        width: 48px;
    }
}

.mini-header{
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.5s all ease;
    z-index: 1;
    

    &__act{
        background: #fff ;
    }
}

.mini-header-nav{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    top: 0px;
    left: -100%;
    transition: 0.2s all linear;
    background-color: #fff;
    padding: 50px 0 0;
    z-index: -1;
    box-shadow: rgb(0 0 0 / 13%) 0 4px 16px;
    
    
    &>.leng-body>.leng-case>button{
        padding: 0;
        line-height:auto;
    }

    &>.nav-header-case>a>button{
        width: 100%;
        font-size: 36px;
        text-align: center;

    }

    &__act{
        left: 0%;
    }

    &>.nav-header-case{
        display: flex;
        flex-direction: column;
    }
}

.burger{
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #000;

    &_toggle-top{
        width: 20px;
        height: 3px;
        margin: 0px 0 0;
        background-color: #fff;
        transition: 0.2s all linear;
        &__act{
            transform: rotate(45deg) translate(4px, 3px);
        }
    }

    &_toggle-mid{
        width: 20px;
        height: 3px;
        margin: 3px 0 0;
        background-color: #fff;
        transition: 0.2s all linear;
        &__act{
            opacity: 0;
        }
    }

    &_toggle-botton{
        width: 20px;
        height: 3px;
        margin: 3px 0 0;
        background-color: #fff;
        transition: 0.2s all linear;
        &__act{
            transform: rotate(-45deg) translate(5px, -5px);
        }
    }
}

@media (max-width:1250px){
    .header_case{
        margin: 0 20px;
    }
}