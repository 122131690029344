.button-up{
    position: fixed;
    bottom: 30px;
    right: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    transition: all 0.5s;
    &:hover{
        transform: scale(1.2);
    }
    &__dis{
        right: -500px;
    }
}