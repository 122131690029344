.partners{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafcfd;
    padding: 90px 0;
    overflow: hidden;
    &_case{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 1200px;

        &>h1{
            font-weight: 600;
            color: #1e294c;
            margin: 0 0 40px;
        }
    }

    &_info{
        font-size: 22px;
        line-height: 1.5;
        font-weight: 400;
        opacity: 1;
        margin:0 0 90px;
    }
}

.partner-link-case{
    display: flex;
}

.partner-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px;
    padding: 30px 30px 40px;
    margin: 0 20px;
    text-decoration: none;
    transition: 0.5s all;
    &>img{
        padding: 0 0 37px;
    }

    &_title{
        font-size: 20px;
        line-height: 1.35;
        font-weight: 600;
        color: #333333;
        opacity: 1;
        text-align: center;
        white-space: break-spaces;
    }

    &_lear{
        font-size: 16px;
        line-height: 1.55;
        font-weight: 700;
        text-decoration: none;
        color: #2e61a1;
        opacity: 1;
        margin: 10px 0 0;
    }

    &:hover{
        transform: scale(1.02);
    }
}

.arrow::after{
    content: " →";
    font-family: Arial, Helvetica, sans-serif;
}

@media(max-width:1220px){
    .partner-link-case {
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr; 
        gap: 10% 10%; 
        grid-template-areas: 
          "case-partner0 case-partner1"
          "case-partner2 case-partner3"; 
      }
      #case-partner0 { grid-area: case-partner0; }
      #case-partner1 { grid-area: case-partner1; }
      #case-partner2 { grid-area: case-partner2; }
      #case-partner3 { grid-area: case-partner3; }
}

@media(max-width:790px){
    .partner-link-case {
        display: grid; 
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 1fr 1fr 1fr; 
        gap: 10px 10px; 
        grid-template-areas: 
            "case-partner0"
            "case-partner1"
            "case-partner2"
            "case-partner3";
      }
      #case-partner0 { grid-area: case-partner0; }
      #case-partner1 { grid-area: case-partner1; }
      #case-partner2 { grid-area: case-partner2; }
      #case-partner3 { grid-area: case-partner3; }
}

@media(max-width:465px){
    .partners_case > span {
        width: 320px;
        text-align: center;
    }
}