.mobile-app{
    display: flex;

    &_case{
        display: flex;
        flex-direction: column;
        &>h1{
            font-size: 38px;
            font-weight: 600;
            text-align: center;
            color: #1e294c;
        }
    }

    &_info{
        display: flex;
        align-items: center;
        overflow: hidden;
        &>img{
            max-width: 560px;
            width: 100%;
            width: 100%;
        }
    }
}

.info-mobile{
    display: flex;
    align-items: flex-start;
    max-width: 560px;
    padding: 50px 0 0;
    &>div>span{
        display: block;
    }
    &_case{
        padding: 0 0 0 30px;
    }

    &_title{
        font-size: 26px;
        font-weight: 600;
        padding-bottom: 15px;
        opacity: 1;
    }

    &_info{
        font-size: 14px;
        font-weight: 400;
        opacity: 1;
    }

    &__act_anim{
        animation: slideRight 0.7s ease; 
    }
}

@keyframes slideRight{
    0%{
        transform: translateX(200%);
        opacity: 0;
    }

    100%{
        transform: translateX(0%);
        opacity:1;
    }
}

@media(max-width:1220px){
    .mobile-app_info{
        flex-direction: column;
    }
}