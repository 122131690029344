.abaut-as{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 195px 0 0;
    scroll-behavior: smooth;
    &_case{
        max-width: 1160px;
        width: 100%;
        overflow: hidden;
        &>.title-border{
            margin: 75px auto 90px;
        }
    }
}

.abaut-info{
    display: flex;
    flex-direction: column;

    &_title{
        font-size: 42px;
        font-weight: 600;
        opacity: 1;
        color: #1e294c;
        margin: 0 0 40px;
    }
    
    &_info{
        font-size: 18px;
        line-height: 1.55;
        font-weight: 400;
        color: #000;
    }
}

.abaut-icon{
    display: flex;
    justify-content: space-evenly;
    padding: 30px 0 45px;
    &_case{
        display: flex;
        flex-direction: column;
        align-items: center;
        

        &>img{
            width: 96px;
        }

        &>span{
            font-size: 20px;
            line-height: 1.17;
            font-weight: 600;
            opacity: 1;
            color: #333;
            text-align: center;
            margin: 25px 0 0 ;
        }
    }
}